import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Nav from './Nav.js'
import Footer from './Footer.js'
import Home from './Home.js'
import About from './About.js'
import Contact from './Contact.js'
import '../semantic/dist/semantic.min.css'
import '../style.css'

const routes = [
  { path: '/',
    exact: true,
    main: () => <Home />
  },
  { path: '/about',
    exact: true,
    main: () => <About />
  },
  { path: '/contact',
    exact: true,
    main: () => <Contact />
  }
]

class App extends Component {
  render() {
    return (
        <Router>
          <Nav />
            {routes.map((route, index) => ( <Route key={index} path={route.path} exact={route.exact} component={route.main} /> ))}
          <Footer />
        </Router>
    );
  }
}
export default App;
