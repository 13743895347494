import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'

class Subscribe extends Component {
  render() {
    return (
      <Modal trigger={<Button className="ui huge primary button">Get Notified<i className="right arrow icon"></i></Button>} closeIcon>
       <Modal.Header>GET NOTIFIED WITH NEWS AND UPDATES</Modal.Header>
       <Modal.Content>
         <Modal.Description>
          <div id="mc_embed_signup">
          <form action="https://cloudbrigade.us14.list-manage.com/subscribe/post?u=d6c73792151d294e7e19ba3ae&amp;id=28f6835f96" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="ui form" target="_blank" noValidate>
              <div id="mc_embed_signup_scroll">
          <div className="ui negative message"><span className="asterisk">*</span> indicates required</div>
          <div className="field">
            <label htmlFor="mce-FNAME">First Name  <span className="asterisk">*</span>
          </label>
            <input type="text" name="FNAME" className="required" id="mce-FNAME" />
          </div>
          <div className="field">
            <label htmlFor="mce-LNAME">Last Name  <span className="asterisk">*</span>
          </label>
            <input type="text" name="LNAME" className="required" id="mce-LNAME" />
          </div>
          <div className="field">
            <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
          </label>
            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
          </div>
          <div className="field">
            <label htmlFor="mce-MMERGE5">Job Title  <span className="asterisk">*</span>
          </label>
            <input type="text" name="MMERGE5" className="required" id="mce-MMERGE5" />
          </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{display:'none'}}></div>
              <div className="response" id="mce-success-response" style={{display:'none'}}></div>
            </div>
              <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
              <input type="text" name="b_d6c73792151d294e7e19ba3ae_28f6835f96" tabIndex="-1" style={{display:'none'}}/></div>
              <div className="clear">
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="ui primary button" /></div>
              </div>
          </form>
          </div>
         </Modal.Description>
       </Modal.Content>
     </Modal>

    );
  }
}
export default Subscribe;
