import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import clock from '../art/priorly-clock.svg'

class Nav extends Component {
  render() {
    return (
      <div className="ui borderless main menu" >
          <div className="header item">
            <Link to="/" className="item">
              <img className="logo" src={clock} alt='priorly'/>
            </Link>
          </div>
          <Link to="/about" className="item">ABOUT</Link>
          <Link to="/contact" className="item">CONTACT</Link>
      </div>
    );
  }
}
export default Nav;
