import React, { Component } from 'react'
import background from '../art/untangle-time.jpg'

class About extends Component {
  render() {
    const styles = {
      masthead: {
        backgroundImage: 'url('+background+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '380px'
      }
    }
    return (
      <div className="content about">
        <div className="ui inverted vertical masthead center aligned segment hero" style={styles.masthead}>
          <div className="ui text container">
            <h1 className="ui inverted header">ABOUT</h1>
          </div>
        </div>
        <div className="ui vertical segment">
          <div className="ui middle aligned stackable grid container">
              <div className="sixteen wide column">
                <h2>IT WAS ABOUT TIME</h2>
                <p>Priorly was created to address the growing problem of time tracking and productivity. As the demands for our output increase in the digital age, it's critically important to track your time and understand where it all goes. Priorly addresses this problem by keeping track of your digital life. What if you could identify the tasks that needlessly burn your time, and recover that time to focus on what is most important. </p>
                <h4>THE BENEFITS ARE MANY</h4>
                <ul>
                  <li>Identify process bottlenecks</li>
                  <li>Avoid leaving money on the table for those "5 minute" tasks</li>
                  <li>Learn what tasks you should be delegating to others</li>
                  <li>Improve productivity and billable time across your team</li>
                </ul>
                <p>Our team is working hard on the upcoming release of the Priorly platform. Get notified about updates and our product release by joining our mailing list below.</p>
              </div>
          </div>
        </div>
        <div className="ui vertical segment">
          <div className="ui middle aligned stackable grid container">
              <div className="sixteen wide column">

          <div id="mc_embed_signup">
          <form action="https://cloudbrigade.us14.list-manage.com/subscribe/post?u=d6c73792151d294e7e19ba3ae&amp;id=28f6835f96" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="ui form" target="_blank" noValidate>
              <div id="mc_embed_signup_scroll">
          <div className="ui negative message"><span className="asterisk">*</span> indicates required</div>
          <div className="field">
            <label htmlFor="mce-FNAME">First Name  <span className="asterisk">*</span>
          </label>
            <input type="text" name="FNAME" className="required" id="mce-FNAME" />
          </div>
          <div className="field">
            <label htmlFor="mce-LNAME">Last Name  <span className="asterisk">*</span>
          </label>
            <input type="text" name="LNAME" className="required" id="mce-LNAME" />
          </div>
          <div className="field">
            <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
          </label>
            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
          </div>
          <div className="field">
            <label htmlFor="mce-MMERGE5">Job Title  <span className="asterisk">*</span>
          </label>
            <input type="text" name="MMERGE5" className="required" id="mce-MMERGE5" />
          </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{display:'none'}}></div>
              <div className="response" id="mce-success-response" style={{display:'none'}}></div>
            </div>
              <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
              <input type="text" name="b_d6c73792151d294e7e19ba3ae_28f6835f96" tabIndex="-1" style={{display:'none'}}/></div>
              <div className="clear">
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="ui primary button" /></div>
              </div>
          </form>
          </div>
          </div>
      </div>
    </div>
      </div>
    );
  }
}
export default About;
