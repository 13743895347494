import React, { Component } from 'react'
import logo from '../art/priorly-logo-animated.svg'
import chip from '../art/chip.svg'
import stats from '../art/statistics.svg'
import Subscribe from './Subscribe'
import { Button, Modal } from 'semantic-ui-react'

class Home extends Component {
  render() {
    return (
      <div className="content home">
        <div className="ui inverted vertical masthead center aligned segment hero">
          <div className="ui text container">
            <h1 className="ui inverted header">
              <img src={logo} className="logo-hero" alt="logo" />
            </h1>
            <h3>Let your time track itself.</h3>
            <Subscribe />
            <Button className="ui huge primary button"><a href="https://www.priorlyapp.com/">Signup<i className="right arrow icon"></i></a></Button>
          </div>
        </div>
        <div className="ui vertical stripe segment">
          <div className="ui middle aligned stackable grid container">
            <div className="center aligned row">
              <div className="twelve wide centered column">
                <h2>PRIORLY IS A TIME TRACKING AND PRODUCTIVITY PLATFORM</h2>
                <h3>Priorly automatically tracks your daily digital activities, answering the age old question - What did I accomplish today?</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="ui vertical stripe quote segment">
          <div className="ui equal width stackable internally celled grid">
            <div className="center aligned row">
              <div className="column">
                <h3><img src={chip} className="icon" alt="logo" /></h3>
                <p>Using artificial intelligence, Priorly generates a ledger of your activities which can be integrated with your billing system, providing efficient and accurate invoices.</p>
              </div>
              <div className="column">
                <h3><img src={stats} className="icon" alt="logo" /></h3>
                <p>With a deep analytics layer and feedback system, Priorly identifies distractions and other process bottlenecks, allowing you to recover that time and focus on what is most important.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
