import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <div className="ui inverted vertical footer segment">
        <div className="ui container">
          <div className="ui stackable inverted divided equal height stackable grid">
            <div className="sixteen wide column">
              <p>© 2019 Priorly, Inc.  All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
