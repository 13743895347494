import React, { Component } from 'react'
import background from '../art/overview.jpg'

class Contact extends Component {
  render() {
    const styles = {
      masthead: {
        backgroundImage: 'url('+background+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '380px'
      }
    }
    return (
      <div className="content contact">
        <div className="ui inverted vertical masthead center aligned segment hero" style={styles.masthead}>
          <div className="ui text container">
            <h1 className="ui inverted header">CONTACT</h1>
          </div>
        </div>
        <div className="ui vertical stripe segment">
          <div className="ui middle aligned stackable grid container">
            <div className="center aligned row">
              <div className="sixteen wide centered column">
              </div>
            </div>
          </div>
        </div>
        <div className="ui vertical stripe quote segment">
          <div className="ui equal width stackable internally celled grid">
            <div className="center aligned row">
              <div className="center aligned column">
              <h4>PRIORLY, Inc.</h4>
              101 Cooper Street<br />
              Santa Cruz, CA 95060<br />
              <a href="mailto:contact@prior.ly">contact@prior.ly</a>
              </div>
            </div>
          </div>
        </div>
        <div className="ui vertical stripe segment">
          <div className="ui text center aligned container">
            </div>
        </div>
      </div>
    );
  }
}
export default Contact;
